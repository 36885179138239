/** Wraps Apollo `useQuery` with NextAuth `useGetUser` before making request */

import type { LazyQueryHookOptions, WatchQueryFetchPolicy } from '@apollo/client'
import { useLazyQuery } from '@apollo/client'
import type { DocumentNode } from 'graphql'
import { useEffect, useMemo, useRef } from 'react'
import { useSession } from '~/components/providers/UaSessionProvider/UaSessionProvider'

export interface UseQueryWithAuthOptions<T = unknown> extends LazyQueryHookOptions<T> {
	/** skip automatically making this request */
	skip?: boolean
	requireRegisteredUser?: boolean
}

export function useQueryWithAuth<T = unknown>(query: DocumentNode, options?: UseQueryWithAuthOptions<T>) {
	const { user } = useSession()

	// Setup Data
	const queryExecuted = useRef(false)

	// Configure `query` function
	const combinedOptions = useMemo(() => {
		const fetchPolicy: WatchQueryFetchPolicy = 'no-cache'
		return {
			fetchPolicy,
			...options,
		}
	}, [options])
	const [executeQuery, queryResults] = useLazyQuery<T>(query, combinedOptions)

	// Run `query` only after authentication (if authentication is needed)
	useEffect(() => {
		if (queryExecuted.current || !user?.uacapiAccessToken) return
		if (options?.requireRegisteredUser && !user.isRegistered) return
		if (options?.skip) return

		executeQuery()
		queryExecuted.current = true
	}, [executeQuery, options?.requireRegisteredUser, options?.skip, user])

	return { ...queryResults } as const
}
