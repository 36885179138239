import type { MessageTriggers } from '~/lib/types/product.interface'
import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import { isVariantOutOfStock } from '~/lib/products'
import { useProductDetailContext } from '~/components/providers/ProductDetailProvider/ProductDetailProvider'

import styles from './VariantMessages.module.scss'
import InfoOutlinedIcon from '~/components/primitives/icons/InfoOutlinedIcon'

export interface MessageTriggerProps {
	/**
	 * - An object containing all possible message state values as well as the selectedVariant object. These messages are a child of the ProductForm component and will display below the SizeChips whenever a condition to display a message is triggered. Whenever the `messageTriggers` object is updated, another `useMemo` runs in ProductForm to set a boolean indicating whether or not there is a value set to `true` in `messageTriggers`. If there are no message values set to `true`, the `VariantMessage` component does not get rendered.
	 */
	messageTriggers?: MessageTriggers
}

/**
 * The `VariantMessages` component is used to display product messages on the PDP in regards to the item that is selected. These messages could be prompted by a selected item being out of stock, low in stock, or by the user needing to select an option before adding something to their cart
 * 
 * @component
 *
 * @param {MessageTriggers} messageTriggers - An object containing all possible message state values as well as the selectedVariant object. These messages are a child of the ProductForm component and will display below the SizeChips whenever a condition to display a message is triggered. Whenever the `messageTriggers` object is updated, another `useMemo` runs in ProductForm to set a boolean indicating whether or not there is a value set to `true` in `messageTriggers`. If there are no message values set to `true`, the `VariantMessage` component does not get rendered.
 *
 * @returns {JSX.Element} The `VariantMessages` component, displaying product-related messages.

 */

const VariantMessages = ({ messageTriggers }: MessageTriggerProps): JSX.Element => {
	const formatMessage = useFormatMessage()

	const {
		forceOutOfStockError,
		selectedVariant,
		sizeInvalid,
		shouldShowLowInventoryMessage,
		showUnavailableMessage,
		variantOutOfStock,
	} = messageTriggers || {}

	const { requiredSizeError } = useEnhancedMessages()

	return (
		<div className={styles.messages}>
			{sizeInvalid && (
				<div id="sizes-error" role="alert" className={styles['sizes-error']}>
					<InfoOutlinedIcon data-icon size="SM" />
					<span>{requiredSizeError}</span>
				</div>
			)}
			{/* shows only if it is not OOS or unavailable */}
			{shouldShowLowInventoryMessage && (
				<div className={styles['low-stock-message']}>{formatMessage('low-stock-msg')}</div>
			)}
			{showUnavailableMessage && (
				<div data-testid="out-of-stock-message" className={styles['out-of-stock-message']}>
					{selectedVariant && isVariantOutOfStock(selectedVariant)
						? formatMessage('out-of-stock')
						: formatMessage('exchange-not-eligible')}
				</div>
			)}
			{/* TODO: Message is ambiguous because it refers to size. It should refer the variant or combo instead.
			 * This is a concern for Screen Reader users, not visual users.
			 */}
			{(variantOutOfStock || forceOutOfStockError) && (
				<div id="out-of-stock-error" role="alert">
					{formatMessage('out-of-stock-add-to-bag')}
				</div>
			)}
		</div>
	)
}

export default VariantMessages

function useEnhancedMessages() {
	const formatMessage = useFormatMessage()
	const { hasEnhancedBuyPanel } = useProductDetailContext()

	if (hasEnhancedBuyPanel) {
		return {
			requiredSizeError: formatMessage('required-size-error-add-to-bag'),
		}
	}

	return {
		requiredSizeError: formatMessage('required-size-error'),
	}
}
