import Image from '~/components/primitives/Image'

import { useFormatMessage } from '~/components/hooks/useFormatMessage'
import FlameIcon from '~/components/primitives/icons/FlameIcon'
import type { ProductBadges as Badges } from '~/graphql/generated/uacapi/type-document-node'
import logger from '~/lib/logger'
import type { Optional } from '~/types/strict-null-helpers'
import { isNonNullish } from '~/types/strict-null-helpers'
import styles from './ProductBadge.module.scss'

export type ProductBadgeProps = {
	badges: Badges
	imageryPlacement: 'top-right' | 'bottom-left' | 'bottom-left-offset'
	textPlacement?: 'top-left' | 'inline'
	textSize: 'small' | 'large'
	accessibilityId?: string
	exclude?: 'imagery' | 'text' | undefined
	isOutlet?: boolean
}

type BadgeSpecs = {
	image: string
	label: string
}

// maps ContentIds to Scene7 images and label translation entries
// Note: Ideally this part should be using dynamic content that comes from
// UACAPI ("bottomLeft" is actually the ID of a ContentAsset).
// However, we're hardcoding for now because
// * There are currently only a few possible images to show here
// * The cleanest way to accomplish this would involve extending UACAPI.
// See https://underarmour.atlassian.net/browse/SB-472
const IMAGERY_BADGES: { [key: string]: BadgeSpecs } = {
	/* eslint-disable @typescript-eslint/naming-convention */
	// no dashes in homemade keys is nice, but these keys come from elsewhere.
	'BHM-logo-LG': { image: 'BHM_Logo', label: 'black-history-month' },
	'IWD-badge': {
		image: 'SS22_BRND_IWD_Badge',
		label: 'womens-international-day',
	},
	'pride-badge-2022': { image: 'SS22_BRND_Pride_Badge', label: 'pride' },
	'ua-run': { image: 'MMR_Logo', label: 'map-my-run' },
	/* eslint-enable @typescript-eslint/naming-convention */
}

export const ProductBadge = ({
	badges,
	imageryPlacement,
	textPlacement = 'top-left',
	textSize,
	accessibilityId,
	exclude,
	isOutlet = false,
}: ProductBadgeProps) => {
	const formatMessage = useFormatMessage()

	const newProductText = formatMessage('new-product')
	const textStyle = styles[`text-badge-${textSize}`]

	let textDisplay: Optional<JSX.Element> = null
	if (badges.upperLeftFlameIcon && !isOutlet) {
		textDisplay = (
			<div
				data-testid="badge-new"
				aria-hidden={isNonNullish(accessibilityId)}
				className={`${styles['product-badge-new']} ${textStyle}`}
				id={accessibilityId}
			>
				<FlameIcon title={newProductText} size="MD" />
				<span>{formatMessage(badges.upperLeftFlameIcon.toLowerCase())}</span>
			</div>
		)
	} else if (
		!badges.upperLeftFlameIcon &&
		badges.upperLeft &&
		badges.upperLeft !== 'new-arrival' &&
		!(isOutlet && badges.upperLeft === formatMessage('product-badge-new-colors'))
	)
		textDisplay = (
			<div
				data-testid="badge-alt"
				aria-hidden={isNonNullish(accessibilityId)}
				className={`${styles['product-badge-alt']} ${textStyle}`}
				id={accessibilityId}
			>
				<span>{badges.upperLeft}</span>
			</div>
		)

	let imageryDisplay: Optional<JSX.Element> = null
	const imageryContentId = badges.bottomLeft
	if (imageryContentId) {
		const badgeSpecs = IMAGERY_BADGES[imageryContentId]
		if (badgeSpecs) {
			const label = formatMessage(badgeSpecs.label)
			imageryDisplay = (
				<Image
					data-testid="imagery-badge"
					layout="fixed"
					src={`https://underarmour.scene7.com/is/image/Underarmour/${badgeSpecs.image}?&fmt=png-alpha&size=96,96`}
					title={label}
					alt={label}
					width="32"
					height="32"
				/>
			)
		} else if (imageryContentId !== 'new') {
			// new is expected and outdated as we're using the flameIcon nowadays
			logger.warn(`Unrecognized imagery badge content id: ${imageryContentId}`)
		}
	}

	return (
		<>
			{textDisplay && exclude !== 'text' && (
				<div className={`${styles[`product-badge-${textPlacement}`]} ${textStyle}`}>{textDisplay}</div>
			)}
			{imageryDisplay && exclude !== 'imagery' && (
				<div className={styles[`imagery-badge-${imageryPlacement}`]}>{imageryDisplay}</div>
			)}
		</>
	)
}
